import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import {SaekoAlumnosService} from '../../../../services/SaekoAlumnosService/SaekoAlumnosService';
import IconButton from "@material-ui/core/IconButton";
import {
	Person,
	Phone,
	Email,
	SearchOutlined,
	EditOutlined,
	FeaturedPlayList,
	Home,
	School,
	Check,
	PhotoCameraOutlined,
	SaveOutlined,
	CancelOutlined,
	PinDropOutlined,
	StreetviewOutlined,
	ImageAspectRatioOutlined,
	GrainOutlined,
	HdrWeakOutlined,
	HdrStrongOutlined, Description, AddOutlined, PlaylistAddOutlined, Edit, DeleteOutlined, Send
} from '@material-ui/icons';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography";
import {CONFIG} from "../../../../settings/Config/Config";
import {FileBase64} from "../../../../settings/FileBase64/FileBase64";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Fotograia from "../../../../assets/img/icons/usuario.png";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import ModalEmpresa from "../../EmpresaFacturacion/Includes/ModalEmpresaFacturacion";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalCliente from "../../ClienteFacturacion/Includes/ModalClienteFacturacion";
import {PopupService} from "../../../../settings/PoPup/PoPup";


class ModalSaekoAlumnos extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			id_saeko_alumno: props.item.id_saeko_alumno || 0,
			tipo: props.tipo,
			
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			numero_emergencia: '',
			correo_electronico: '',
			tipo_sangre: '',
			fecha_nacimiento: null,
			fecha_inscripcion: null,
			grupo: '',
			grado: '',
			curp: '',
			rfc: '',
			genero: '',
			turno: '',
			ciclo_escolar: '',
			id_cat_estado_civil: '',
			lugar_nacimiento: '',
			position: '',
			tipo_descuento: '',
			seguro_medico: '',
			alergia: '',
			enfermedad: '',
			medicamento: '',
			discapacidad: '',
			id_cat_estado_procedencia: '',
			id_cat_municipio_procedencia: '',
			id_cat_nivel_sep: '',
			escuela: '',
			promedio: '',
			fecha_inicio: null,
			fecha_fin: null,
			cedula: '',
			ednia: '',
			comunidad: '',
			folio_ceneval: '',
			activo: '',
			
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			referencia: '',
			
			editar_familiar: 0,
			nombre_familiar: '',
			id_parentesco_familiar: '',
			fecha_nacimiento_familiar: null,
			lugar_nacimiento_familiar: null,
			datos_familiares: [],
			telefono_familiar: '',
			correo_electronico_familiar: '',
			
			cat_estado: [],
			cat_municipio: [],
			cat_municipio_procedencia: [],
			cat_sexo: [],
			cat_parentesco: [],
			
			base64Tipo: '',
			base64: '',
			foto: '',
			formato: '',
			
			
			id_empresa: '',
			listar_empresa: [],
			empresa: null,
			id_cliente: '',
			listar_cliente: [],
			cliente: null,
			
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	ListarEstado = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEstado().then((response) => {
				this.setState({
					cat_estado: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListarMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		return new Promise((resolve, reject) => {
			CatService.ListMunicipio(id_cat_estado).then((response) => {
				this.setState({
					cat_municipio: response.data
				});
				resolve(response);
			});
		});
	};
	
	ListarMunicipioProcedencia = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		return new Promise((resolve, reject) => {
			CatService.ListMunicipio(id_cat_estado).then((response) => {
				this.setState({
					cat_municipio_procedencia: response.data
				});
				resolve(response);
			});
		});
	};
	
	
	save = () => {
		if (this.state.id_saeko_alumno > 0) {
			SaekoAlumnosService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			SaekoAlumnosService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.RefreshList();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.ListarEstado();
		this.ListaEmpresa().then(() => {
			this.setState({
				id_empresa: 54 || '',
			})
			for (let i = 0; i < this.state.listar_empresa.length; i++) {
				if (54 == Number(this.state.listar_empresa[i].id_empresa)) {
					this.setState({
						empresa: this.state.listar_empresa[i] || null,
					})
				}
			}
		});
		this.ListaCliente();
		this.ListaParentesco();
		
		this.setState({
			id_saeko_alumno: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			numero_emergencia: '',
			correo_electronico: '',
			tipo_sangre: '',
			fecha_nacimiento: null,
			fecha_inscripcion: null,
			grupo: '',
			grado: '',
			curp: '',
			rfc: '',
			genero: '',
			turno: '',
			ciclo_escolar: '',
			id_cat_estado_civil: '',
			lugar_nacimiento: '',
			position: '',
			tipo_descuento: '',
			seguro_medico: '',
			alergia: '',
			enfermedad: '',
			medicamento: '',
			discapacidad: '',
			id_cat_estado_procedencia: '',
			id_cat_municipio_procedencia: '',
			id_cat_nivel_sep: '',
			escuela: '',
			promedio: '',
			fecha_inicio: null,
			fecha_fin: null,
			cedula: '',
			ednia: '',
			comunidad: '',
			folio_ceneval: '',
			activo: 1,
			base64Tipo: '',
			base64: '',
			foto: '',
			formato: '',
			
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			referencia: '',
			
			editar_familiar: 0,
			nombre_familiar: '',
			id_cat_parentesco: '',
			parentesco_familiar: '',
			fecha_nacimiento_familiar: null,
			lugar_nacimiento_familiar: null,
			datos_familiares: [],
			
			id_empresa: '',
			empresa: null,
			id_cliente: '',
			cliente: null,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {item} = this.props;
		
		this.ListarEstado().then(() => {
			this.setState({id_cat_estado: item.id_cat_estado || ''});
		});
		
		if (FieldsJs.Field(item.id_cat_municipio) && Number(item.id_cat_municipio) > 0) {
			this.ListarMunicipio(null, item.id_cat_estado).then(() => {
				this.setState({id_cat_municipio: item.id_cat_municipio || ''});
			});
		} else {
			this.setState({id_cat_municipio: ''});
		}
		
		this.ListaEmpresa().then(() => {
			this.setState({
				id_empresa: item.id_empresa || '',
			})
			for (let i = 0; i < this.state.listar_empresa.length; i++) {
				if (54 == Number(this.state.listar_empresa[i].id_empresa)) {
					this.setState({
						empresa: this.state.listar_empresa[i] || null,
					})
				}
			}
		});
		
		
		this.ListaCliente().then(() => {
			this.setState({
				id_cliente: item.id_cliente || '',
			})
			for (let i = 0; i < this.state.listar_cliente.length; i++) {
				if (Number(item.id_cliente) == Number(this.state.listar_cliente[i].id_cliente)) {
					this.setState({
						cliente: this.state.listar_cliente[i] || null,
					})
				}
			}
		});
		
		this.ListaParentesco().then((response) => {
			this.setState({
				cat_parentesco: response || ''
			})
		});
		
		this.setState({
			id_saeko_alumno: item.id_saeko_alumno || '',
			nombre: item.nombre || '',
			apellido_paterno: item.primer_apellido || '',
			apellido_materno: item.segundo_apellido || '',
			telefono: item.telefonos || '',
			numero_emergencia: item.numero_emergencia || '',
			correo_electronico: item.correo_institucional || '',
			tipo_sangre: item.tipo_sangre || '',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			fecha_inscripcion: item.fecha_inscripcion ? moment(item.fecha_inscripcion) : null,
			grupo: item.grupo || '',
			grado: item.grado || '',
			curp: item.curp || '',
			rfc: item.rfc || '',
			genero: item.genero || '',
			turno: item.turno || '',
			ciclo_escolar: item.ciclo || '',
			id_cat_estado_civil: item.estado_civil || '',
			lugar_nacimiento: item.lugar_nacimiento || '',
			position: item.position || '',
			tipo_descuento: item.tipo_descuento || '',
			seguro_medico: item.seguro_medico || '',
			alergia: item.alergia || '',
			enfermedad: item.enfermedad || '',
			medicamento: item.medicamento || '',
			discapacidad: item.discapacidad || '',
			id_cat_estado_procedencia: item.id_cat_estado_procedencia || '',
			id_cat_municipio_procedencia: item.id_cat_municipio_procedencia || '',
			id_cat_nivel_sep: item.id_cat_nivel_sep || '',
			escuela: item.escuela || '',
			promedio: item.promedio || '',
			fecha_inicio: item.fecha_inicio ? moment(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? moment(item.fecha_fin) : null,
			cedula: item.cedula || '',
			ednia: item.ednia || '',
			comunidad: item.comunidad || '',
			folio_ceneval: item.folio_ceneval || '',
			archivo: item.foto || item.fotografia || '',
			foto: item.foto || item.fotografia || '',
			
			base64Tipo: '',
			base64: '',
			formato: '',
			
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			referencia: item.referencia || '',
			
			editar_familiar: 0,
			nombre_familiar: '',
			id_parentesco_familiar: '',
			parentesco_familiar: '',
			fecha_nacimiento_familiar: null,
			lugar_nacimiento_familiar: '',
			telefono_familiar: '',
			correo_electronico_familiar: '',
			datos_familiares: item.datos_familiares || [],
			
			activo: (Number(item.activo) === 1),
		});
		
		this.openModal('paper');
	};
	
	view = () => {
		
		const {item} = this.props;
		
		this.ListarEstado().then(() => {
			this.setState({id_cat_estado: item.id_cat_estado || ''});
		});
		
		if (FieldsJs.Field(item.id_cat_municipio) && Number(item.id_cat_municipio) > 0) {
			this.ListarMunicipio(null, item.id_cat_estado).then(() => {
				this.setState({id_cat_municipio: item.id_cat_municipio || ''});
			});
		} else {
			this.setState({id_cat_municipio: ''});
		}
		
		this.ListaEmpresa().then(() => {
			this.setState({
				id_empresa: item.id_empresa || '',
			})
			for (let i = 0; i < this.state.listar_empresa.length; i++) {
				if (54 == Number(this.state.listar_empresa[i].id_empresa)) {
					this.setState({
						empresa: this.state.listar_empresa[i] || null,
					})
				}
			}
		});
		
		
		this.ListaCliente().then(() => {
			this.setState({
				id_cliente: item.id_cliente || '',
			})
			for (let i = 0; i < this.state.listar_cliente.length; i++) {
				if (Number(item.id_cliente) == Number(this.state.listar_cliente[i].id_cliente)) {
					this.setState({
						cliente: this.state.listar_cliente[i] || null,
					})
				}
			}
		});
		
		this.ListaParentesco().then((response) => {
			this.setState({
				cat_parentesco: response || ''
			})
		});
		
		this.setState({
			id_saeko_alumno: item.id_saeko_alumno || '',
			nombre: item.nombre || '',
			apellido_paterno: item.primer_apellido || '',
			apellido_materno: item.segundo_apellido || '',
			telefono: item.telefonos || '',
			numero_emergencia: item.numero_emergencia || '',
			correo_electronico: item.correo_institucional || '',
			tipo_sangre: item.tipo_sangre || '',
			fecha_nacimiento: item.fecha_nacimiento ? moment(item.fecha_nacimiento) : null,
			fecha_inscripcion: item.fecha_inscripcion ? moment(item.fecha_inscripcion) : null,
			grupo: item.grupo || '',
			grado: item.grado || '',
			curp: item.curp || '',
			rfc: item.rfc || '',
			genero: item.genero || '',
			turno: item.turno || '',
			ciclo_escolar: item.ciclo || '',
			id_cat_estado_civil: item.estado_civil || '',
			lugar_nacimiento: item.lugar_nacimiento || '',
			position: item.position || '',
			tipo_descuento: item.tipo_descuento || '',
			seguro_medico: item.seguro_medico || '',
			alergia: item.alergia || '',
			enfermedad: item.enfermedad || '',
			medicamento: item.medicamento || '',
			discapacidad: item.discapacidad || '',
			id_cat_estado_procedencia: item.id_cat_estado_procedencia || '',
			id_cat_municipio_procedencia: item.id_cat_municipio_procedencia || '',
			id_cat_nivel_sep: item.id_cat_nivel_sep || '',
			escuela: item.escuela || '',
			promedio: item.promedio || '',
			fecha_inicio: item.fecha_inicio ? moment(item.fecha_inicio) : null,
			fecha_fin: item.fecha_fin ? moment(item.fecha_fin) : null,
			cedula: item.cedula || '',
			ednia: item.ednia || '',
			comunidad: item.comunidad || '',
			folio_ceneval: item.folio_ceneval || '',
			archivo: item.foto || item.fotografia || '',
			
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			editar_familiar: 0,
			nombre_familiar: '',
			id_parentesco_familiar: '',
			parentesco_familiar: '',
			fecha_nacimiento_familiar: null,
			lugar_nacimiento_familiar: '',
			telefono_familiar: '',
			correo_electronico_familiar: '',
			datos_familiares: item.datos_familiares || [],
			
			activo: (Number(item.activo) === 1),
		});
		
		this.openModal('paper');
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_saeko_alumno > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	fileSelect = () => {
		document.getElementById('cambiar_foto_alumno').click();
	};
	
	limpiar_foto_de_portada = () => {
		this.setState({
			base64Tipo: '',
			base64: '',
			foto: '',
			formato: '',
		});
		document.getElementById("cambiar_foto_alumno").value = '';
	};
	
	getBase64 = (e) => {
		let formatos = [
			"image/jpeg",
			"image/png"
		];
		FileBase64.Base64(e.target, formatos).then((response) => {
			this.setState({
				base64Tipo: response.base64Tipo,
				base64: response.base64,
				foto: response.archivo,
				formato: response.formato,
			});
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
			this.setState({
				base64Tipo: '',
				base64: '',
				foto: '',
				formato: '',
			});
		});
	};
	
	agregarFamiliar = () => {
		try {
			
			if (!this.state.nombre) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Nombre.'
				})
			}
			
			if (!this.state.id_parentesco_familiar) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Parentesco.'
				})
			}
			
			if (!this.state.telefono_familiar) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Teléfono.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.datos_familiares);
			let fecha_nacimiento = (this.state.fecha_nacimiento_familiar || null) ? DateFormat.FormatSql(this.state.fecha_nacimiento_familiar) : null;
			let cp = FieldsJs.Copy(this.state.cat_parentesco);
			let parentesco = '';
			if (cp) {
				for (let i = 0; i < cp.length; i++) {
					if (Number(cp[i].id_cat_parentesco) === Number(this.state.id_parentesco_familiar)) {
						parentesco = cp[i].parentesco;
					}
				}
			}
			let item = {
				nombre: this.state.nombre_familiar,
				id_parentesco_familiar: this.state.id_parentesco_familiar,
				parentesco: parentesco,
				fecha_nacimiento: fecha_nacimiento,
				lugar_nacimiento: this.state.lugar_nacimiento_familiar,
				telefono: this.state.telefono_familiar,
				correo_electronico: this.state.correo_electronico_familiar
			}
			partidas.push(item);
			this.setState({
				datos_familiares: partidas,
				nombre_familiar: '',
				id_parentesco_familiar: '',
				fecha_nacimiento_familiar: null,
				lugar_nacimiento_familiar: '',
				telefono_familiar: '',
				correo_electronico_familiar: ''
			});
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	}
	
	modificarFamiliar = () => {
		try {
			
			if (!this.state.nombre) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Nombre.'
				})
			}
			
			if (!this.state.id_parentesco_familiar) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Parentesco.'
				})
			}
			
			if (!this.state.telefono_familiar) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Teléfono.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.datos_familiares);
			let fecha_nacimiento = (this.state.fecha_nacimiento_familiar || null) ? DateFormat.FormatSql(this.state.fecha_nacimiento_familiar) : null;
			let cp = FieldsJs.Copy(this.state.cat_parentesco);
			let parentesco = '';
			if (cp) {
				for (let i = 0; i < cp.length; i++) {
					if (Number(cp[i].id_cat_parentesco) === Number(this.state.id_parentesco_familiar)) {
						parentesco = cp[i].parentesco;
					}
				}
			}
			
			let item = {
				id_saeko_alumnos_familiares: this.state.id_saeko_alumnos_familiares,
				nombre: this.state.nombre_familiar,
				id_cat_parentesco: this.state.id_parentesco_familiar,
				parentesco: parentesco,
				fecha_nacimiento: fecha_nacimiento,
				lugar_nacimiento: this.state.lugar_nacimiento_familiar,
				telefono: this.state.telefono_familiar,
				correo_electronico: this.state.correo_electronico_familiar
			}
			
			partidas[Number(this.state.editar_familiar) - 1] = item;
			
			this.setState({
				datos_familiares: partidas,
				nombre_familiar: '',
				id_parentesco_familiar: '',
				fecha_nacimiento_familiar: null,
				lugar_nacimiento_familiar: '',
				telefono_familiar: '',
				correo_electronico_familiar: '',
				editar_familiar: 0
			}, () => {
			});
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	
	editarFamiliar = (value, key) => {
		try {
			console.log(value, key);
			this.setState({
				editar_familiar: key + 1,
				id_saeko_alumnos_familiares: value.id_saeko_alumnos_familiares,
				nombre_familiar: value.nombre,
				id_parentesco_familiar: value.id_cat_parentesco,
				fecha_nacimiento_familiar: value.fecha_nacimiento,
				lugar_nacimiento_familiar: value.lugar_nacimiento,
				telefono_familiar: value.telefono,
				correo_electronico_familiar: value.correo_electronico,
				
			});
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminarFamiliar = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar al familiar?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.datos_familiares);
					
					partidas.splice(key, 1);
					
					this.setState({
						datos_familiares: partidas
					});
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	
	handleChangeEmpresa = (id_empresa, valor) => {
		console.log(id_empresa.tarjet, valor);
		this.setState({
			empresa: valor,
			id_empresa: valor.id_empresa
		});
		this.ListaEmpresaSucursal(valor.id_empresa);
	}
	
	handleChangeCliente = (id_cliente, valor) => {
		console.log(id_cliente.tarjet, valor);
		this.setState({
			cliente: valor,
			id_cliente: valor.id_cliente
		});
		this.clienteRecidenciaRegistro(valor.id_cliente);
	}
	
	ListaEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaFac(2, this.Usr.id_usuario, this.Usr.id_plaza).then((response) => {
				this.setState({
					listar_empresa: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_empresa: []
				});
				reject(error);
				this.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListaEmpresaSucursal = (id_empresa) => {
		
		/*let empresas = FieldsJs.Copy(this.state.listar_empresa);
		for (let i = 0; i < empresas.length; i++) {
			if (Number(id_empresa) === Number(empresas[i].id_empresa)) {
				this.setState({
					rfc_emisor: empresas[i].rfc,
				});
			}
		}*/
		
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				} else {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	
	ListaCliente = () => {
		return new Promise((resolve, reject) => {
			CatService.ListClienteFac(null).then((response) => {
				this.setState({
					listar_cliente: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_cliente: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	ListaParentesco = () => {
		return new Promise((resolve, reject) => {
			CatService.ListParentesco().then((response) => {
				this.setState({
					cat_parentesco: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					cat_parentesco: []
				});
				this.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	};
	
	clienteRecidenciaRegistro = (id_cliente) => {
		for (let i = 0; i < this.state.listar_cliente.length; i++) {
			if (Number(id_cliente) === Number(this.state.listar_cliente[i].id_cliente)) {
				this.setState({
					cliente_regimen_fiscal: this.state.listar_cliente[i].descripcion ? this.state.listar_cliente[i].regimen_fiscal + ' - ' + this.state.listar_cliente[i].descripcion : this.state.listar_cliente[i].regimen_fiscal,
					cliente_numero_registro_if: this.state.listar_cliente[i].numero_registro_if,
					cliente_codigo_postal: this.state.listar_cliente[i].codigo_postal,
					enviar_a: this.state.listar_cliente[i].correo_electronico ?? [],
					enviar_cc: this.state.listar_cliente[i].correo_electronico_copia ?? [],
					rfc_receptor: this.state.listar_cliente[i].rfc,
					nombre_razon_social: this.state.listar_cliente[i].nombre_razon_social ?? ''
					
				});
			}
		}
	}
	
	render() {
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		const foto = this.state.base64 ? this.state.base64 : this.state.foto ? CONFIG.src + this.state.foto : Fotograia;
		
		const bg_foto = {
			backgroundColor: `white`,
			padding: 10,
			backgroundImage: `url(${foto})`
		};
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'md'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>
						{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Alumno
					</DialogTitle>
					
					<DialogContent className={'padding-20 padding-0-T'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
								<Grid container spacing={2}>
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													required
													name="nombre"
													label="Nombre(s)"
													value={this.state.nombre}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													required
													name="apellido_paterno"
													label="Apellido Paterno"
													value={this.state.apellido_paterno}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													name="apellido_materno"
													label="Apellido Materno"
													value={this.state.apellido_materno}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<FeaturedPlayList className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													name="curp"
													required
													label="CURP"
													value={this.state.curp}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Genero</InputLabel>
													<Select
														native
														value={this.state.genero}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="genero"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														<option key={'1'} value={'Femenino'}>{'Femenino'}</option>
														<option key={'2'} value={'Masculino'}>{'Masculino'}</option>
														<option key={'3'} value={'No especificar'}>{'No especificar'}</option>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											margin="dense"
											className={'margin-0'}
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha de Inscripción"
											value={this.state.fecha_inscripcion}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_inscripcion');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
										
										/>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Phone className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													type={'text'}
													margin="dense"
													fullWidth
													name="telefono"
													onChange={this.handleChange}
													label="Teléfono" autoComplete={'off'}
													value={this.state.telefono}
													inputProps={{maxLength: 10}}
													disabled={this.state.tipo === 'view'}
													onKeyPress={EnteroSolo}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Email className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													name="correo_electronico"
													label="Correo electrónico"
													value={this.state.correo_electronico}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									{/*<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Phone className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													type={'text'}
													margin="dense"
													fullWidth
													name="numero_emergencia"
													onChange={this.handleChange}
													label="Número de Emergencia" autoComplete={'off'}
													value={this.state.numero_emergencia}
													inputProps={{maxLength: 10}}
													disabled={this.state.tipo === 'view'}
													onKeyPress={EnteroSolo}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<HdrWeakOutlined className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField
													className={'margin-0'}
													fullWidth
													onChange={this.handleChange}
													disabled={this.state.tipo === 'view'}
													type="text"
													margin="dense"
													name="tipo_sangre"
													label="Tipo Sangre"
													value={this.state.tipo_sangre}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											margin="dense"
											className={'margin-0'}
											clearable
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha nacimiento"
											value={this.state.fecha_nacimiento}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_nacimiento');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											disabled={this.state.tipo === 'view'}
										
										/>
									</Grid>*/}
									
								</Grid>
							</Grid>
							
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<Card style={bg_foto} className={'bg-img-cover-x-center-y-center'}>
									<CardContent className={'h-100-84-px'} style={{padding: '15px', border: 'gray 2px dashed'}}>
										<div style={{height: 170, width: '100%'}} align={'right'}>
											<input type="file" id="cambiar_foto_alumno"
											       onChange={(e) => this.getBase64(e)} className={'display-none'}/>
											<Grid
												container
												direction="row"
												justify="flex-end"
												alignItems="center"
											>
												{!this.state.base64Tipo ? (
													<Grid item>
														<IconButton aria-label="Cambiar foto"
														            onClick={() => this.fileSelect()}>
															<PhotoCameraOutlined style={{color: 'gray'}}/>
														</IconButton>
													</Grid>
												) : null}
												{!!this.state.base64Tipo ? (
													<Fragment>
														<Grid item>
															<IconButton aria-label="Cancelar foto"
															            onClick={() => this.limpiar_foto_de_portada()}>
																<CancelOutlined style={{color: 'gray'}}/>
															</IconButton>
														</Grid>
														<Grid item>
															<IconButton aria-label="Actualizar foto"
															            onClick={() => this.fileSelect()}>
																<SaveOutlined style={{color: 'gray'}}/>
															</IconButton>
														</Grid>
													</Fragment>
												) : null}
											</Grid>
										</div>
									</CardContent>
								</Card>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Ciclo Escolar</InputLabel>
											<Select
												native
												value={this.state.ciclo_escolar}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="ciclo_escolar"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												<option key={'1'} value={'Ciclo anual 2024'}>Ciclo anual 2024</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Plan de Estudios</InputLabel>
											<Select
												native
												value={this.state.grupo}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="grupo"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												<option key={'1'} value={'Ciclo anual 2024'}>Ciclo anual 2024</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							
							
							<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="grado"
											label="Grado"
											value={this.state.grado}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Turno</InputLabel>
											<Select
												native
												value={this.state.turno}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="turno"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												<option key={'1'} value={'Matutino'}>Matutino</option>
												<option key={'2'} value={'Vespertino'}>Vespertino</option>
												<option key={'3'} value={'Mixto'}>Mixto</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FeaturedPlayList className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="rfc"
											label="RFC"
											value={this.state.rfc}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							*<Grid item className={'text-left'} xs={12} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Estado Civil</InputLabel>
											<Select
												native
												value={this.state.id_cat_estado_civil}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_estado_civil"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												<option key={'Soltero'} value={'Soltero'}>{'Soltero'}</option>
												<option key={'Casado'} value={'Casado'}>{'Casado'}</option>
												<option key={'Divorciado'} value={'Divorciado'}>{'Divorciado'}</option>
												<option key={'Viudo'} value={'Viudo'}>{'Viudo'}</option>
												<option key={'Unión libre'} value={'Unión libre'}>{'Unión libre'}</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Home className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											className={'margin-0'}
											fullWidth
											onChange={this.handleChange}
											disabled={this.state.tipo === 'view'}
											type="text"
											margin="dense"
											name="lugar_nacimiento"
											label="Lugar de Nacimiento"
											value={this.state.lugar_nacimiento}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							
							
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Dirección</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<PinDropOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="calle"
														           onChange={this.handleChange}
														           label="Calle" autoComplete={'off'}
														           value={this.state.calle}
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<StreetviewOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="numero_exterior"
														           onChange={this.handleChange}
														           label="Numero Exterior" autoComplete={'off'}
														           value={this.state.numero_exterior}
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<StreetviewOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="numero_interior"
														           onChange={this.handleChange}
														           label="Numero Interior" autoComplete={'off'}
														           value={this.state.numero_interior}
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="colonia"
														           onChange={this.handleChange}
														           label="Colonia" autoComplete={'off'}
														           value={this.state.colonia}
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<ImageAspectRatioOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="codigo_postal"
														           onChange={this.handleChange}
														           label="Código Postal" autoComplete={'off'}
														           inputProps={{maxLength: 5}}
														           onKeyPress={EnteroSolo}
														           value={this.state.codigo_postal}
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrWeakOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<FormControl fullWidth>
															<InputLabel>Estado</InputLabel>
															<Select
																native
																value={this.state.id_cat_estado}
																onChange={(e) => {
																	this.handleChange(e);
																	this.ListarMunicipio(e);
																}}
																name="id_cat_estado"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																{this.state.cat_estado.map(option => (
																	<option key={option.id_cat_estado}
																	        value={option.id_cat_estado}>
																		{option.estado}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrongOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														
														<FormControl fullWidth>
															<InputLabel>Municipio</InputLabel>
															<Select
																native
																value={this.state.id_cat_municipio}
																onChange={this.handleChange}
																name="id_cat_municipio"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																{this.state.cat_municipio.map(option => (
																	<option key={option.id_cat_municipio}
																	        value={option.id_cat_municipio}>
																		{option.municipio}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="referencia"
														           onChange={this.handleChange}
														           label="Referencia" autoComplete={'off'}
														           value={this.state.referencia}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<Typography>Datos Familiares</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Person className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="nombre_familiar"
														           onChange={this.handleChange}
														           label="Nombre Completo" autoComplete={'off'}
														           value={this.state.nombre_familiar}
														           multiline
														           disabled={this.props.tipo === 'view'}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrWeakOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<FormControl fullWidth>
															<InputLabel>Parentesco</InputLabel>
															<Select
																native
																value={this.state.id_parentesco_familiar}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																name="id_parentesco_familiar"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
																required
															>
																<option value=""/>
																{this.state.cat_parentesco.map(option => (
																	<option key={option.id_cat_parentesco}
																	        value={option.id_cat_parentesco}>
																		{option.parentesco}
																	</option>
																))}
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Phone className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															className={'margin-0'}
															type={'text'}
															margin="dense"
															fullWidth
															name="telefono_familiar"
															onChange={this.handleChange}
															label="Teléfono" autoComplete={'off'}
															value={this.state.telefono_familiar}
															inputProps={{maxLength: 10}}
															disabled={this.state.tipo === 'view'}
															onKeyPress={EnteroSolo}
															required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Email className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															className={'margin-0'}
															fullWidth
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
															type="text"
															margin="dense"
															name="correo_electronico_familiar"
															label="Correo electrónico"
															value={this.state.correo_electronico_familiar}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											{/*<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<KeyboardDatePicker
													format={'dd/MM/yyyy'}
													fullWidth
													margin="dense"
													className={'margin-0'}
													clearable
													inputProps={{readOnly: true}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
													label="Fecha de Nacimiento"
													value={this.state.fecha_nacimiento_familiar}
													onChange={(date) => {
														this.handleChange(null, null, null, date, 'fecha_nacimiento_familiar');
													}}
													clearLabel={'Limpiar'}
													okLabel={'Aceptar'}
													cancelLabel={'Cancelar'}
													disabled={this.state.tipo === 'view'}
												
												/>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Home className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField
															className={'margin-0'}
															fullWidth
															onChange={this.handleChange}
															disabled={this.state.tipo === 'view'}
															type="text"
															margin="dense"
															name="lugar_nacimiento_familiar"
															label="Lugar de Nacimiento"
															value={this.state.lugar_nacimiento_familiar}
														/>
													</Grid>
												</Grid>
											</Grid>*/}
											
											<Grid item xs={12} sm={6} md={2} lg={1} xl={1} align={'right'}>
												<div className={'w-auto vertical-inline h-100'}>
													{this.state.editar_familiar > 0 ? (
														<div className={'v-center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={"Modificar Familiar"}
																children={(
																	<Fab size="small"
																	     aria-label={"Modificar Familiar"}
																	     onClick={() => this.modificarFamiliar()}
																	     style={{marginLeft: "15px", background: '#55709e', color: 'white'}}>
																		<Check/>
																	</Fab>
																)}
															/>
														</div>
													) : (
														<div className={'v-center'}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={"Agregar Familiar"}
																children={(
																	<Fab size="small" color="primary"
																	     aria-label={"Agregar Familiar"}
																	     onClick={() => this.agregarFamiliar()}
																	     style={{marginLeft: "15px"}}>
																		<AddOutlined/>
																	</Fab>
																)}
															/>
														</div>
													)}
												</div>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={1}>
													{this.state.datos_familiares.length > 0 ? (
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<table className={'desing-mark'} style={{minWidth: 100}}>
																<thead>
																<tr>
																	<th align={"left"}>Nombre Completo</th>
																	<th align={"left"}>Parentesco</th>
																	<th align={"left"}>Teléfono</th>
																	<th align={"left"}>Correo Electrónico</th>
																	<th align={"right"}>Acciones</th>
																</tr>
																</thead>
																<tbody>
																{this.state.datos_familiares.map((item, index) => (
																		<tr key={index}>
																			<td align={"left"}>{item.nombre ?? '-'}</td>
																			<td align={"left"}>{item.parentesco ?? '-'}</td>
																			<td align={"left"}>{item.telefono ?? '-'}</td>
																			<td align={"left"}>{item.correo_electronico ?? '-'}</td>
																			<td align={'right'}>
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Eliminar"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Eliminar"
																							onClick={() => this.eliminarFamiliar(item, index)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "transparent",
																								color: "#808080",
																								boxShadow: "none",
																							}}
																							children={(
																								<DeleteOutlined/>
																							)}
																						/>
																					)}
																				/>
																				<Tooltip
																					TransitionComponent={Zoom}
																					placement={"top"}
																					title="Editar"
																					children={(
																						<Fab
																							size="small"
																							aria-label="Editar"
																							onClick={() => this.editarFamiliar(item, index)}
																							style={{
																								marginLeft: "10px",
																								backgroundColor: "transparent",
																								color: this.state.editarFamiliar > 0 ? '#f50057' : "#808080",
																								boxShadow: "none",
																							}}
																							children={(
																								<Edit/>
																							)}
																						/>
																					)}
																				/>
																			</td>
																		</tr>
																	)
																)}
																</tbody>
															</table>
														</Grid>
													) : null}
												</Grid>
											</Grid>
										
										
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel4a-content"
										id="panel4a-header"
									>
										<Typography>Facturación</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													
													<Grid item style={{width: '50px'}}>
														<ModalEmpresa
															componente={(
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"right"}
																	title="Agregar nueva empresa"
																	children={(
																		<Fab
																			color="default"
																			size="small"
																			aria-label="Agregar nueva empresa"
																			children={(
																				<PlaylistAddOutlined/>
																			)}
																		/>
																	)}
																/>
															)}
															id_empresa={null}
															tipo={'add'}
															item={{}}
															RefreshList={this.RefreshListEmpresa}
															showSnackBars={this.showSnackBars}
														/>
													</Grid>
													
													{FieldsJs.Field(this.state.id_empresa) ? (
														<Grid item style={{width: '50px'}}>
															<ModalEmpresa
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Editar empresa"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Editar empresa"
																				children={(
																					<Edit/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_empresa={null}
																tipo={'edit'}
																item={{id_empresa: this.state.id_empresa}}
																RefreshList={this.RefreshListEmpresa}
																showSnackBars={this.showSnackBars}
															/>
														</Grid>
													) : null}
													<Grid item style={{width: FieldsJs.Field(this.state.id_empresa) ? 'calc(100% - 100px)' : 'calc(100% - 50px)'}}>
														
														<Autocomplete
															id="autocomplete_empresa"
															options={this.state.listar_empresa}
															value={this.state.empresa}
															getOptionLabel={(option) => option.rfc + ' - ' + option.nombre_razon_social}
															noOptionsText={'No se encontron datos.'}
															renderInput={(params) => <TextField {...params} label="Empresa  *" margin="none"/>}
															onChange={(e, value) => {
																this.handleChangeEmpresa(e, value);
															}}
															disabled
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
													
													<Grid item style={{width: '50px'}}>
														<ModalCliente
															componente={(
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"right"}
																	title="Agregar nuevo cliente"
																	children={(
																		<Fab
																			color="default"
																			size="small"
																			aria-label="Agregar nuevo cliente"
																			children={(
																				<PlaylistAddOutlined/>
																			)}
																		/>
																	)}
																/>
															)}
															id_cliente={null}
															tipo={'add'}
															item={{}}
															RefreshList={this.RefreshListFacturacion}
															showSnackBars={this.showSnackBars}
														/>
													</Grid>
													
													{ FieldsJs.Field(this.state.id_cliente) ? (
														<Grid item style={{width: '50px'}}>
															<ModalCliente
																componente={(
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"right"}
																		title="Editar cliente"
																		children={(
																			<Fab
																				color="default"
																				size="small"
																				aria-label="Editar cliente"
																				children={(
																					<Edit/>
																				)}
																			/>
																		)}
																	/>
																)}
																id_cliente={null}
																tipo={'edit'}
																item={{id_cliente: this.state.id_cliente}}
																RefreshList={this.RefreshListFacturacion}
																showSnackBars={this.showSnackBars}
															/>
														</Grid>
													) : null}
													<Grid item style={{width: FieldsJs.Field(this.state.id_cliente) ? 'calc(100% - 100px)' : 'calc(100% - 50px)'}}>
														<Autocomplete
															id="autocomplete_receptor"
															options={this.state.listar_cliente}
															value={this.state.cliente}
															getOptionLabel={(option) => option.rfc + ' - ' + option.nombre_razon_social}
															noOptionsText={'No se encontron datos.'}
															renderInput={(params) => <TextField {...params} label="RFC Receptor  *" margin="none"/>}
															onChange={(e, value) => {
																this.handleChangeCliente(e, value);
															}}
															disabled={this.state.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
										
										
										
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<Typography>Detalles Medicos</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<span className={'text-left text-black px-13'}>¿Cuentas con seguro médico?</span>
												<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
													<RadioGroup
														row aria-label="position"
														name="seguro_medico"
														value={this.state.seguro_medico}
														onChange={this.handleChange}>
														
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"SI"}
															label={"SI"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"NO"}
															label={"NO"}
															disabled={this.props.tipo === 'view'}
														/>
													</RadioGroup>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="alergia"
														           onChange={this.handleChange}
														           label="Alergias" autoComplete={'off'}
														           value={this.state.alergia}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="enfermedad"
														           onChange={this.handleChange}
														           label="Enfermedades" autoComplete={'off'}
														           value={this.state.enfermedad}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="discapacidad"
														           onChange={this.handleChange}
														           label="Discapacidad" autoComplete={'off'}
														           value={this.state.discapacidad}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="medicamento"
														           onChange={this.handleChange}
														           label="Medicamentos" autoComplete={'off'}
														           value={this.state.medicamento}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel3a-content"
										id="panel3a-header"
									>
										<Typography>Procedencia</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrWeakOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<FormControl fullWidth>
															<InputLabel>Estado</InputLabel>
															<Select
																native
																value={this.state.id_cat_estado_procedencia}
																onChange={(e) => {
																	this.handleChange(e);
																	this.ListarMunicipioProcedencia(e);
																}}
																name="id_cat_estado_procedencia"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																{this.state.cat_estado.map(option => (
																	<option key={option.id_cat_estado}
																	        value={option.id_cat_estado}>
																		{option.estado}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrongOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														
														<FormControl fullWidth>
															<InputLabel>Municipio</InputLabel>
															<Select
																native
																value={this.state.id_cat_municipio_procedencia}
																onChange={this.handleChange}
																name="id_cat_municipio_procedencia"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																{this.state.cat_municipio_procedencia.map(option => (
																	<option key={option.id_cat_municipio}
																	        value={option.id_cat_municipio}>
																		{option.municipio}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrongOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														
														<FormControl fullWidth>
															<InputLabel>Nivel SEP</InputLabel>
															<Select
																native
																value={this.state.id_cat_nivel_sep}
																onChange={this.handleChange}
																name="id_cat_nivel_sep"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																<option key={'1'} value={'PRIMARIA'}>Primaria</option>
																<option key={'2'} value={'SECUNDARIA'}>Secundaria</option>
																<option key={'3'} value={'MEDIA SUPERIOR'}>Media Superior</option>
																<option key={'4'} value={'SUPERIOR'}>Superior</option>
																<option key={'5'} value={'FORMACIÓN PARA EL TRABAJO'}>Formación para el trabajo</option>
																<option key={'6'} value={'CAM'}>Cam</option>
																<option key={'7'} value={'OTRO NIVEL EDUCATIVO'}>Otro Nivel Educativo</option>
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<School className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="escuela"
														           onChange={this.handleChange}
														           label="Escuela" autoComplete={'off'}
														           value={this.state.escuela}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="promedio"
														           onChange={this.handleChange}
														           label="Promedio" autoComplete={'off'}
														           value={this.state.promedio}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<KeyboardDatePicker
													format={'dd/MM/yyyy'}
													fullWidth
													margin="dense"
													className={'margin-0'}
													clearable
													inputProps={{readOnly: true}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
													label="Fecha Inicio"
													value={this.state.fecha_inicio}
													onChange={(date) => {
														this.handleChange(null, null, null, date, 'fecha_inicio');
													}}
													clearLabel={'Limpiar'}
													okLabel={'Aceptar'}
													cancelLabel={'Cancelar'}
													disabled={this.state.tipo === 'view'}
												
												/>
											</Grid>
											
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<KeyboardDatePicker
													format={'dd/MM/yyyy'}
													fullWidth
													margin="dense"
													className={'margin-0'}
													clearable
													inputProps={{readOnly: true}}
													KeyboardButtonProps={{
														'aria-label': 'change date',
													}}
													label="Fecha Fin"
													value={this.state.fecha_fin}
													onChange={(date) => {
														this.handleChange(null, null, null, date, 'fecha_fin');
													}}
													clearLabel={'Limpiar'}
													okLabel={'Aceptar'}
													cancelLabel={'Cancelar'}
													disabled={this.state.tipo === 'view'}
												
												/>
											</Grid>
											
											
											<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="cedula"
														           onChange={this.handleChange}
														           label="Cédula" autoComplete={'off'}
														           value={this.state.cedula}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon/>}
										aria-controls="panel4a-content"
										id="panel4a-header"
									>
										<Typography>Datos Adicionales</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<span className={'text-left text-black px-13'}>¿Perteneces a alguna etni indígena?</span>
												<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
													<RadioGroup
														row aria-label="position"
														name="ednia"
														value={this.state.ednia}
														onChange={this.handleChange}>
														
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"SI"}
															label={"SI"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"NO"}
															label={"NO"}
															disabled={this.props.tipo === 'view'}
														/>
													</RadioGroup>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<span className={'text-left text-black px-13'}>¿Hablas alguna lengua indígena?</span>
												<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
													<RadioGroup
														row aria-label="position"
														name="lengua"
														value={this.state.lengua}
														onChange={this.handleChange}>
														
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"SI"}
															label={"SI"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"NO"}
															label={"NO"}
															disabled={this.props.tipo === 'view'}
														/>
													</RadioGroup>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
												<span className={'text-left text-black px-13'}>¿Vives en una comunidad indígena?</span>
												<Grid item className={'text-left ticketfrecuencia'} xs={12} sm={12} md={12} lg={12} xl={12}>
													<RadioGroup
														row aria-label="position"
														name="comunidad"
														value={this.state.comunidad}
														onChange={this.handleChange}>
														
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"SI"}
															label={"SI"}
															disabled={this.props.tipo === 'view'}
														/>
														<FormControlLabel
															style={{color: 'black', fontSize: '13px'}}
															labelPlacement="end"
															control={<Radio color="primary"/>}
															value={"NO"}
															label={"NO"}
															disabled={this.props.tipo === 'view'}
														/>
													</RadioGroup>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<GrainOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="folio_ceneval"
														           onChange={this.handleChange}
														           label="Folio Ceneval" autoComplete={'off'}
														           value={this.state.folio_ceneval}
														           multiline
														           disabled={this.props.tipo === 'view'}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</Grid>
							
						
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_usuario > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSaekoAlumnos.propTypes = {
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalSaekoAlumnos);
