import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {FieldsJs} from "../../../settings/General/General";

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodoPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodo_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodicidadTipo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodicidad_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMenu: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/menu').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMenuPertenece: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/menu_pertenece').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioRocketChat: (rocketchat) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario/rocketchat').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresa: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListBanco: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/banco').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroCliente: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_cliente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioEjecutivo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_ejecutivo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioPromotor: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_promotor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioSocio: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_socio').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioQuiniela: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_quiniela').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClienteInternaPlazaDireccion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cliente_interna_plaza_direccion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatTicketStatus: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/ticket_status').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFacturacionPlaza: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/facturacion_plaza').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFacturacionStatusSat: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/facturacion_status_sat').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFacturacionStatusPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/facturacion_status_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFrecuenciaNotificacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/ticket_frecuencia_notificacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRecordatorioFrecuenciaNotificacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/recordatorio_frecuencia_notificacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoAdquisicion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_adquisicion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoAdquisicionPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_adquisicion_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFacturacionMotivoCancelacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/facturacion_motivo_cancelacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPiloto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/piloto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEscuderia: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/escuderia').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMiembros: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/miembros').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSeriesCfdi: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/series_cfdi').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListServicioCompra: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/servicio_compra').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListServicioGasto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/servicio_gasto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodicidad: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodicidad').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProyecto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proyecto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProyectoModulo: (id_proyecto) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proyecto_modulo' + (id_proyecto > 0 ? '/' + id_proyecto: '')).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCronogramaEstatus: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cronograma_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEquipo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/equipo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstadoEquipo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado_equipo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPlataformas: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/plataformas').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoDocumentoRJ: (id_cat_tipo_usuario) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_documento_repositorio_juridico/'+id_cat_tipo_usuario).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCentroTrabajoRJ: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/centro_trabajo_repositorio_juridico').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaSucursal: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_sucursal').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoImpuesto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_impuesto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoDeclaracion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_declaracion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoDocumentoEstadoCuenta: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_documento_estados_cuenta').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoPagoComprobantePago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_pago_comprobante_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListAcuseNumeroRegistro: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/acuse_numero_registro').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRepositorioJuridicoEstatus: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/repositorio_juridico_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRegimenFiscal: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/regimen_fiscal').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListLineaCapturaMotivoCancelacion: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/linea_captura_motivo_cancelacion').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListLineaCapturaImpuesto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/linea_captura_impuesto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListParentesco: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/parentesco').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPais: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/pais', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListRegimenFiscalFac: (id_cat_tipo_persona = 1) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/regimen_fiscal/'+id_cat_tipo_persona, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClaveUnidad: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/clave_unidad', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClaveProductoServicio: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/clave_producto_servicio', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMoneda: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/moneda', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaFac: (id_proyecto, id_usuario, id_plaza) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa'+ (id_proyecto ? '/'+id_proyecto:'') + (id_usuario ? '/'+id_usuario:'') + (id_plaza ? '/'+id_plaza:''), {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaSucursalFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_sucursal/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpresaRegimenFiscalFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_regimen_fiscal/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClienteFac: (id_cliente) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get(FieldsJs.Field(id_cliente) > 0 ? 'cat/cliente/2/'+id_cliente : 'cat/cliente/2', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoRetenidoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto_retenido', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListImpuestoTrasladoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/impuesto_traslado', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoFactorFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_factor', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsoCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/uso_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMetodoPagoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/metodo_pago', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPagoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMonedaFac: (id_empresa) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empresa_moneda/'+id_empresa, {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListObjetoImpuestoFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/objeto_impuesto', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodicidadCFDI: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodicidad', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoRelacionFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_relacion', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCancelacionCFDIFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/cancelacion_cfdi', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListExportacionFac: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/exportacion', {}, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEmpleado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/empleado', {}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEjercicio: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/ejercicio', {}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSaekoLead: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/saeko_lead', {}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSaekoTag: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/saeko_tag', {}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
