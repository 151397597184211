import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {MonetizationOn, Description, Send} from "@material-ui/icons";
import {KeyboardDatePicker, KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import ModalDescargaTimbrado from "../../Timbrado/Includes/ModalDescargaTimbrado";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from '@material-ui/core/Zoom/index';
import {SaekoAlumnosService} from "../../../../services/SaekoAlumnosService/SaekoAlumnosService";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import IconButton from "@material-ui/core/IconButton";
import ModalSendMailPago from "./ModalSendMailPago";



class ModalPago extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			open_modal_xml: false,
			
			id_saeko_alumnos_pagos: props.item.id_saeko_alumnos_pagos || '',
			id_saeko_alumno: props.item.id_saeko_alumno || '',
			concepto: '',
			importe: '',
			no_recibo: '',
			notas: '',
			id_cat_forma_pago: '',
			fecha_pago: null,
			partidas: [],
			pagos: [],
			listar_cat_forma_pago: [],
			total: '',
		};
	}
	
	ListaFormaPago = () => {
		CatService.ListFormaPago().then((response) => {
			this.setState({
				listar_cat_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_forma_pago: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaObjetoImpuesto = () => {
		CatService.ListObjetoImpuestoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_objeto_impuesto: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_objeto_impuesto: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChangeSerie = (id_empresa_sucursal_serie, valor) => {
		let series = FieldsJs.Copy(this.state.listar_empresa_sucursal_serie);
		for (let i = 0; i < series.length; i++) {
			if (Number(id_empresa_sucursal_serie) === Number(series[i].id_empresa_sucursal_serie)) {
				this.setState({
					serie: series[i].serie
				});
			}
		}
		
		EmpresaFacturacionExtService.SerieFolio(id_empresa_sucursal_serie).then((response) => {
			this.setState({
				folio: response.data
			});
		}).catch((error) => {
			this.setState({listar_facturacion: []});
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	add = () => {
		let {item} = this.props;
		
		this.ListaFormaPago();
		
		this.setState({
			id_saeko_alumnos_pagos: item.id_saeko_alumnos_pagos || '',
			id_saeko_alumno: item.id_saeko_alumno || '',
			concepto: '',
			importe: '',
			no_recibo: '',
			notas: '',
			fecha_pago: new Date(),
			id_cat_forma_pago: '',
			partidas: [],
			pagos: item.datos_pagos || [],
			total: '',
		});
		
		setTimeout(() => {
			let desglose = this.calcularDesgoseTotales();
			this.setState({
				total: desglose
			});
		}, 500);
		
		this.open();
		
	};
	
	save = () => {
		this.agregarPartida().then((response) => {
			SaekoAlumnosService.AgregarPagos(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}).catch((error)=>{
		
		})
	};
	
	agregarPartida = () => {
		return new Promise((resolve, reject) => {
			try {
				
				if (!this.state.fecha_pago) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Fecha de Pago.'
					})
				}
				
				if (!this.state.concepto) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Concepto.'
					})
				}
				
				if (!this.state.importe) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Importe.'
					})
				}
				
				if (!this.state.id_cat_forma_pago) {
					throw Object({
						status: false, mensaje: 'Campo requerido: Forma de Pago.'
					})
				}
				
				let partidas = FieldsJs.Copy(this.state.partidas);
				
				
				let item = {
					concepto: this.state.concepto || '',
					importe: this.state.importe || '',
				};
				
				partidas.push(item);
				
				this.setState({
					/*concepto: '',
					importe: '',*/
					partidas: partidas,
				});
				
				setTimeout(() => {
					let desglose = this.calcularDesgoseTotales();
					this.setState({
						total: desglose
					});
				}, 500);
				resolve(true);
			} catch (e) {
				this.props.showSnackBars('error', e.mensaje);
				reject();
			}
		});
		
	};
	
	calcularDesgoseTotales = () => {
		let total = 0;
		let part = FieldsJs.Copy(this.state.partidas);
		for (let i = 0; i < part.length; i++) {
			total = Number(total) + Number(part[i].importe)
		}
		return total;
	}
	
	modificarPartida = () => {
		try {
			
			if (!this.state.clave_concepto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Clave Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_producto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Concepto.'
				})
			}
			
			if (!this.state.id_empresa_clave_unidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Unidad de Medida.'
				})
			}
			
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Valor Unitario.'
				})
			}
			
			if (FieldsJs.inArray([2, 3], Number(this.state.tipo_descuento)) && !this.state.descuento) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descuento.'
				})
			}
			
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto Impuesto.'
				})
			}
			
			let impuestos_t = FieldsJs.Field(this.state.impuestos_traladados) ? FieldsJs.Copy(this.state.impuestos_traladados) : [];
			
			if (Number(this.state.id_cat_objeto_impuesto) === 2 && !this.state.iva_incluido && impuestos_t.length <= 0) {
				throw Object({
					status: false, mensaje: 'Debe agregar un impuesto.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			let productos = FieldsJs.Copy(this.state.listar_concepto);
			let unidades_medida = FieldsJs.Copy(this.state.listar_unidad_medida);
			
			let impuestos_r = FieldsJs.Copy(this.state.impuestos_retenidos);
			let impuestos_trasladados_total = 0;
			let impuestos_retenidos_total = 0;
			let descripcion = '';
			let clave = '';
			let unidad_medida = '';
			let clave_unidad = '';
			let unidad = '';
			let importe = 0;
			if (this.state.iva_incluido) {
				importe = (Number(this.state.precio_unitario) / 1.16) * Number(this.state.cantidad);
				let cat_impuesto_t = FieldsJs.Copy(this.state.listar_cat_impuestos_trasladados);
				let cat_tipo_factor = FieldsJs.Copy(this.state.listar_cat_tipo_factor);
				let cat_impuesto = FieldsJs.Copy(this.state.listar_cat_impuestos);
				
				let it = '';
				let ifc = '';
				let im = '';
				
				for (let i = 0; i < cat_impuesto_t.length; i++) {
					if (Number(2) === Number(cat_impuesto_t[i].id_cat_impuesto_trasladado)) {
						it = cat_impuesto_t[i].impuesto_trasladado;
					}
				}
				for (let i = 0; i < cat_tipo_factor.length; i++) {
					if (Number(1) === Number(cat_tipo_factor[i].id_cat_tipo_factor)) {
						ifc = cat_tipo_factor[i].tipo_factor;
					}
				}
				for (let i = 0; i < cat_impuesto.length; i++) {
					if (Number(3) === Number(cat_impuesto[i].id_cat_impuesto)) {
						im = cat_impuesto[i].impuesto;
					}
				}
				
				let item = {
					id_cat_impuesto_trasladado: 2,
					impuesto_trasladado: it,
					tipo_impuesto: 1,
					tipo_factor: ifc,
					id_cat_impuesto: 3,
					tasa_cuota: im,
				};
				
				impuestos_t.push(item);
			} else if (this.state.iva_aplicado) {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
			} else {
				importe = Number(this.state.cantidad) * Number(this.state.precio_unitario);
				
			}
			let descuento = Number(this.state.tipo_descuento) === 2 ? importe * (Number(this.state.descuento) / 100) : Number(this.state.descuento);
			
			for (let i = 0; i < impuestos_t.length; i++) {
				impuestos_t[i].base = importe - descuento;
				impuestos_t[i].importe = (importe - descuento) * (impuestos_t[i].tasa_cuota);
				impuestos_trasladados_total = impuestos_trasladados_total + ((importe - descuento) * (impuestos_t[i].tasa_cuota));
				
			}
			for (let i = 0; i < impuestos_r.length; i++) {
				impuestos_r[i].base = importe - descuento;
				impuestos_r[i].importe = (importe - descuento) * (impuestos_r[i].tasa_cuota);
				impuestos_retenidos_total = impuestos_retenidos_total + ((importe - descuento) * (impuestos_r[i].tasa_cuota));
			}
			
			for (let i = 0; i < productos.length; i++) {
				if (Number(this.state.id_empresa_clave_producto) === Number(productos[i].id_empresa_clave_producto)) {
					clave = productos[i].clave_producto_servicio;
					descripcion = productos[i].descripcion;
				}
			}
			
			for (let i = 0; i < unidades_medida.length; i++) {
				if (Number(this.state.id_empresa_clave_unidad) === Number(unidades_medida[i].id_empresa_clave_unidad)) {
					unidad_medida = unidades_medida[i].concepto ? unidades_medida[i].clave_unidad + ' - ' + unidades_medida[i].concepto : unidades_medida[i].concepto;
					clave_unidad = unidades_medida[i].clave_unidad;
					unidad = unidades_medida[i].concepto;
				}
			}
			
			let item = {
				id_empresa_clave_producto: this.state.id_empresa_clave_producto,
				numero_identificacion: this.state.numero_identificacion,
				id_empresa_clave_unidad: this.state.id_empresa_clave_unidad,
				unidad_medida: unidad_medida,
				clave_unidad: clave_unidad,
				unidad: unidad,
				descripcion: descripcion,
				cantidad: this.state.cantidad,
				precio_unitario: this.state.iva_incluido ? importe : this.state.precio_unitario,
				importe: importe,
				concepto: this.state.concepto,
				clave_concepto: this.state.clave_concepto,
				tipo_descuento: this.state.tipo_descuento,
				descuento: descuento,
				numero_pedimento: this.state.numero_pedimento,
				numero_predial: this.state.numero_predial,
				id_cat_objeto_impuesto: this.state.id_cat_objeto_impuesto,
				trasladados: impuestos_trasladados_total,
				retenidos: impuestos_retenidos_total,
				impuestos_trasladados: impuestos_t,
				impuestos_retenidos: impuestos_r,
				iva_incluido: this.state.iva_incluido ? 1 : 0,
				iva_aplicado: this.state.iva_aplicado ? 1 : 0,
			};
			
			partidas[Number(this.state.editar_partida) - 1] = item;
			
			this.setState({
				
				id_cat_clave_producto_servicio: null,
				id_empresa_clave_unidad: '',
				id_empresa_clave_producto: '',
				descripcion_concepto: '',
				numero_identificacion: '',
				cantidad: 1.0,
				precio_unitario: '',
				concepto: '',
				clave_concepto: '',
				tipo_descuento: '1',
				descuento: '',
				id_cat_impuesto_trasladado: '',
				id_cat_tipo_factor_trasladado: '',
				id_cat_impuesto: '',
				impuestos_trasladados: [],
				numero_pedimento: '',
				numero_predial: '',
				id_cat_objeto_impuesto: '',
				iva_incluido: false,
				iva_aplicado: false,
				iva_exento: false,
				editar_partida: 0,
				
				id_cat_impuesto_retenido: '',
				id_cat_tipo_factor_retenido: '',
				tasa_cuota_retenido: '',
				impuestos_traladados: [],
				impuestos_retenidos: [],
				partidas: partidas,
				autocomplete_concepto: {},
			}, () => {
			});
			
			setTimeout(() => {
				let desglose = this.calcularDesgoseTotales();
				this.setState({
					desglose: desglose
				});
			}, 500);
			
		} catch (e) {
			this.showSnackBars('error', e.mensaje);
		}
	};
	
	editarPartida = (value, key) => {
		try {
			console.log(value, key);
			this.setState({
				editar_partida: key + 1,
				id_empresa_clave_producto: value.id_empresa_clave_producto,
				numero_identificacion: value.numero_identificacion,
				id_empresa_clave_unidad: value.id_empresa_clave_unidad,
				unidad_medida: value.unidad_medida,
				clave_unidad: value.clave_unidad,
				unidad: value.unidad,
				descripcion: value.descripcion,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				importe: value.importe,
				concepto: value.concepto,
				clave_concepto: value.clave_concepto,
				tipo_descuento: value.tipo_descuento,
				descuento: value.descuento,
				numero_pedimento: value.numero_pedimento,
				numero_predial: value.numero_predial,
				id_cat_objeto_impuesto: value.id_cat_objeto_impuesto,
				trasladados: value.trasladados,
				retenidos: value.retenidos,
				impuestos_trasladados: value.impuestos_trasladados,
				impuestos_retenidos: value.impuestos_retenidos,
				iva_incluido: value.iva_incluido ? 1 : 0,
				iva_aplicado: value.iva_aplicado ? 1 : 0,
				iva_exento: value.iva_exento ? 1 : 0,
			}, () => {
				this.obtenerDatosConcepto();
			});
			
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminarPartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la partida?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.partidas);
					
					partidas.splice(key, 1);
					
					this.setState({
						partidas: partidas
					});
					
					setTimeout(() => {
						let desglose = this.calcularDesgoseTotales();
						this.setState({
							total: desglose
						});
					}, 500);
				}
			});
			
		} catch (error) {
			this.props.showSnackBars('error', error.mensaje);
		}
	};
	
	exportar = (item, archivo, auth) => {
		/*this.props.showSnackBars('error', "En proceso de desarrollo");
		return*/
		try {
			
			let filtro = item || {};
			
			let params = {
				auth: auth,
				archivo: archivo,
				filtro: filtro,
			};
			
			HttpRequest.export('saeko_alumnos_pagos', ['pago'], params, false, false, false, archivo === "PDF");
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	

	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.add()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Pagos"
						     className={'margin-10-L'} onClick={() => this.add()}>
							<MonetizationOn className={'px-18'}/>
						</Fab>
					</Fragment>
				)}
				
				<ModalDescargaTimbrado item={this.state} open={this.state.open_modal_xml} showSnackBars={this.showSnackBars} RefreshList={this.RefreshList}/>
				
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Pagos
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-start"}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-left borde-punteado-lightgray-2'}>
								<Grid container spacing={1}>
									<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										Agregar Pago
									</Grid>
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<KeyboardDatePicker
											format={'dd/MM/yyyy'}
											fullWidth
											margin="dense"
											className={'margin-0'}
											clearable
											required
											inputProps={{readOnly: true}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											label="Fecha de Pago"
											value={this.state.fecha_pago}
											onChange={(date) => {
												this.handleChange(null, null, null, date, 'fecha_pago');
											}}
											clearLabel={'Limpiar'}
											okLabel={'Aceptar'}
											cancelLabel={'Cancelar'}
											
										/>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={9} lg={9} xl={9}>
										<TextField
											name="concepto"
											label="Concepto"
											type="text"
											fullWidth
											value={this.state.concepto}
											onChange={this.handleChange}
											required
										/>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
										<TextField
											required
											margin="none"
											name="importe"
											label="Importe"
											type="text"
											fullWidth
											value={this.state.importe}
											onChange={(e) => {
												this.handleChange(e);
											}}
											onKeyPress={EnteroSolo}
										/>
									</Grid>
									
									<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
										<TextField
											select
											fullWidth
											required
											margin="none"
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {
													className: '',
												},
											}}
											name="id_cat_forma_pago"
											label="Forma de pago"
											value={this.state.id_cat_forma_pago}
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.listar_cat_forma_pago.map(option => (
												<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
													{option.forma_pago} - {option.descripcion}
												</option>
											))}
										</TextField>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={7} lg={7} xl={7}>
										<TextField
											name="notas"
											label="Notas importantes"
											type="text"
											fullWidth
											value={this.state.notas}
											onChange={this.handleChange}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1}>
									{this.state.pagos.length > 0 ? (
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<table className={'desing-mark'} style={{minWidth: 100}}>
												<thead>
												<tr>
													<th align={"center"}>No.</th>
													<th align={"center"}>Fecha de Pago</th>
													<th align={"center"}>Concepto</th>
													<th align={"center"}>Forma de Pago</th>
													<th align={"right"}>Importe</th>
													<th align={"right"}>Acciones</th>
												</tr>
												</thead>
												<tbody>
												{this.state.pagos.map((item, index) => (
														<tr key={index}>
															<td align={"center"}>{index + 1}</td>
															<td align={"center"}>{item.fecha_pago}</td>
															<td align={"left"}>{item.concepto}</td>
															<td align={"left"}>{item.forma_pago}</td>
															<td align={"right"}>${FieldsJs.Currency(item.importe)}</td>
															<td align={'right'}>
																<div className={'w-auto vertical-inline'} style={{minHeight: '40px'}}>
																	<div className={'v-center'}>
																		<Tooltip
																			TransitionComponent={Zoom}
																			placement={"top"}
																			title="Comprobante PDF"
																			children={(
																				<Fab
																					size="small"
																					aria-label="Comprobante PDF"
																					onClick={() => this.exportar(item, 'PDF', true)}
																					style={{
																						marginLeft: "10px",
																						backgroundColor: "transparent",
																						color: "#808080",
																						boxShadow: "none",
																					}}
																					children={(
																						<Description/>
																					)}
																				/>
																			)}
																		/>
																	</div>
																	<div className={'v-center'}>
																		<ModalSendMailPago
																			item={this.props.item}
																			pago={item}
																			tipo={'add'}
																			project={2}
																			componente={(
																				<Tooltip TransitionComponent={Zoom} placement={"top"} title="Reenviar Comprobante">
																					<IconButton aria-label="Reenviar Comprobante">
																						<Send className={'v-center'} style={{opacity: 0.7, color: '#e87e44'}}/>
																					</IconButton>
																				</Tooltip>
																			)}
																			RefreshList={this.props.RefreshList}
																			showSnackBars={this.props.showSnackBars}
																			history={this.props.history}
																		/>
																	</div>
																</div>
															</td>
														</tr>
													)
												)}
												</tbody>
											</table>
										</Grid>
									) : (
										<VistaVacia
											numero={0}
											mensaje={'No se encontraron pagos.'}
											width={'80px'}
											height={'80px'}
										/>
									)}
								</Grid>
							</Grid>
							
							
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary" variant={"contained"}>
									Guardar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}

ModalPago.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	item: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPago;
