import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import moment from "moment";
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const SaekoAlumnosService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				name: filtro.name || null,
				id_cat_saeko_lead: filtro.id_cat_saeko_lead || null,
				id_cat_saeko_tag: filtro.id_cat_saeko_tag || null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarTodo: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Datos_All', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_nacimiento = (form.fecha_nacimiento || null) ? DateFormat.FormatSql(form.fecha_nacimiento) : null;
		let fecha_inscripcion = (form.fecha_inscripcion || null) ? DateFormat.FormatSql(form.fecha_inscripcion) : null;
		let fecha_inicio = (form.fecha_inicio || null) ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = (form.fecha_fin || null) ? DateFormat.FormatSql(form.fecha_fin) : null;
		let fecha_nacimiento_familiar = (form.fecha_nacimiento_familiar || null) ? DateFormat.FormatSql(form.fecha_nacimiento_familiar) : null;
		
		let params = {
			id_saeko_alumno: form.id_saeko_alumno || '',
			matricula: form.matricula || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			numero_emergencia: form.numero_emergencia || '',
			correo_electronico: form.correo_electronico || '',
			tipo_sangre: form.tipo_sangre || '',
			fecha_nacimiento: fecha_nacimiento,
			fecha_inscripcion: fecha_inscripcion,
			grupo: form.grupo || '',
			grado: form.grado || '',
			curp: form.curp || '',
			rfc: form.rfc || '',
			ciclo_escolar: form.ciclo_escolar || '',
			genero: form.genero || '',
			turno: form.turno || '',
			id_cat_estado_civil: form.id_cat_estado_civil || '',
			lugar_nacimiento: form.lugar_nacimiento || '',
			calle: form.calle || '',
			numero_exterior: form.numero_exterior || '',
			numero_interior: form.numero_interior || '',
			codigo_postal: form.codigo_postal || '',
			colonia: form.colonia || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			referencia: form.referencia || '',
			position: form.position || '',
			tipo_descuento: form.tipo_descuento || '',
			seguro_medico: form.seguro_medico || '',
			alergia: form.alergia || '',
			enfermedad: form.enfermedad || '',
			medicamento: form.medicamento || '',
			discapacidad: form.discapacidad || '',
			id_cat_estado_procedencia: form.id_cat_estado_procedencia || '',
			id_cat_municipio_procedencia: form.id_cat_municipio_procedencia || '',
			id_cat_nivel_sep: form.id_cat_nivel_sep || '',
			escuela: form.escuela || '',
			promedio: form.promedio || '',
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			cedula: form.cedula || '',
			ednia: form.ednia || '',
			comunidad: form.comunidad || '',
			folio_ceneval: form.folio_ceneval || '',
			foto: form.foto || '',
			formato: form.formato || '',
			activo: form.activo ? 1 : 0,
			
			datos_familiares: form.datos_familiares || '',
			
			id_empresa: form.id_empresa || '',
			empresa: (form.empresa || '').nombre_razon_social,
			id_cliente: form.id_cliente || '',
			cliente: (form.cliente || '').nombre_razon_social
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_nacimiento = (form.fecha_nacimiento || null) ? DateFormat.FormatSql(form.fecha_nacimiento) : null;
		let fecha_inscripcion = (form.fecha_inscripcion || null) ? DateFormat.FormatSql(form.fecha_inscripcion) : null;
		let fecha_inicio = (form.fecha_inicio || null) ? DateFormat.FormatSql(form.fecha_inicio) : null;
		let fecha_fin = (form.fecha_fin || null) ? DateFormat.FormatSql(form.fecha_fin) : null;
		let fecha_nacimiento_familiar = (form.fecha_nacimiento_familiar || null) ? DateFormat.FormatSql(form.fecha_nacimiento_familiar) : null;
		
		let params = {
			id_saeko_alumno: form.id_saeko_alumno || '',
			matricula: form.matricula || '',
			nombre: form.nombre || '',
			apellido_paterno: form.apellido_paterno || '',
			apellido_materno: form.apellido_materno || '',
			telefono: form.telefono || '',
			numero_emergencia: form.numero_emergencia || '',
			correo_electronico: form.correo_electronico || '',
			tipo_sangre: form.tipo_sangre || '',
			fecha_nacimiento: fecha_nacimiento,
			fecha_inscripcion: fecha_inscripcion,
			grupo: form.grupo || '',
			grado: form.grado || '',
			curp: form.curp || '',
			rfc: form.rfc || '',
			ciclo_escolar: form.ciclo_escolar || '',
			turno: form.turno || '',
			genero: form.genero || '',
			id_cat_estado_civil: form.id_cat_estado_civil || '',
			lugar_nacimiento: form.lugar_nacimiento || '',
			calle: form.calle || '',
			numero_exterior: form.numero_exterior || '',
			numero_interior: form.numero_interior || '',
			codigo_postal: form.codigo_postal || '',
			colonia: form.colonia || '',
			id_cat_estado: form.id_cat_estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			referencia: form.referencia || '',
			position: form.position || '',
			tipo_descuento: form.tipo_descuento || '',
			seguro_medico: form.seguro_medico || '',
			alergia: form.alergia || '',
			enfermedad: form.enfermedad || '',
			medicamento: form.medicamento || '',
			discapacidad: form.discapacidad || '',
			id_cat_estado_procedencia: form.id_cat_estado_procedencia || '',
			id_cat_municipio_procedencia: form.id_cat_municipio_procedencia || '',
			id_cat_nivel_sep: form.id_cat_nivel_sep || '',
			escuela: form.escuela || '',
			promedio: form.promedio || '',
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			cedula: form.cedula || '',
			ednia: form.ednia || '',
			comunidad: form.comunidad || '',
			folio_ceneval: form.folio_ceneval || '',
			foto: form.foto || '',
			formato: form.formato || '',
			activo: form.activo ? 1 : 0,
			
			datos_familiares: form.datos_familiares || '',
			
			id_empresa: form.id_empresa || '',
			empresa: (form.empresa || '').nombre_razon_social,
			id_cliente: form.id_cliente || '',
			cliente: (form.cliente || '').nombre_razon_social
			
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarPagos: (form) => {
		let fecha_pago = (form.fecha_pago || null) ? DateFormat.FormatSql(form.fecha_pago) : null;
		let params = {
			id_saeko_alumno: form.id_saeko_alumno || '',
			notas: form.notas || '',
			fecha_pago: fecha_pago || '',
			no_recibo: form.no_recibo || '',
			id_cat_forma_pago: form.id_cat_forma_pago || '',
			partidas: form.partidas || '',
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Pagos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EnviarPagos: (form) => {
		let params = {
			id_saeko_alumnos_pagos: form.id_saeko_alumnos_pagos,
			enviar_a: form.enviar_a || '',
			enviar_cc: form.enviar_cc || '',
			enviar_cco: form.enviar_cco || '',
			produccion: false,
			id_proyecto: 2
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Pagos_Enviar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_saeko_alumno) => {
		let params = {
			id_saeko_alumno: id_saeko_alumno
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	
	ChatDatos: (form, modulo) => {
		let params = {
			filtro: {
				id_cliente: form.id_cliente || null,
				id_ficha_tecnica: form.id_ficha_tecnica || null,
				modulo: modulo,
				activo: 1,
			},
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Chat_Datos', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatGuardar: (form) => {
		let params = {
			id_cliente: form.id_cliente,
			id_ficha_tecnica: form.id_ficha_tecnica,
			sms: form.sms,
			modulo: form.modulo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Saeko_Alumnos_Chat_Guardar', params, {spinner: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
