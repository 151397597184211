import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {HdrStrongOutlined, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";


class FiltrosSaekoAlumnos extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			name: '',
			cat_lead: [],
			cat_tag: [],
			id_cat_saeko_lead: '',
			id_cat_saeko_tag: '',
			activo: '',
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.name = this.state.name || undefined;
		filtro.id_cat_saeko_lead = this.state.id_cat_saeko_lead || undefined;
		filtro.id_cat_saeko_tag = this.state.id_cat_saeko_tag || undefined;
		filtro.activo = this.state.activo || undefined;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item xs={12} sm={12} md={3} lg={3} xl={3} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="name"
									onChange={this.handleChange}
									label="Nombre del Alumno"
									autoComplete={'off'}
									value={this.state.name}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrongOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								
								<FormControl fullWidth>
									<InputLabel>Lead</InputLabel>
									<Select
										native
										value={this.state.id_cat_saeko_lead}
										onChange={this.handleChange}
										name="id_cat_saeko_lead"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.catSaekoLead.map(option => (
											<option key={option.id_cat_saeko_lead}
											        value={option.id_cat_saeko_lead}>
												{option.saeko_lead}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrongOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								
								<FormControl fullWidth>
									<InputLabel>Tag</InputLabel>
									<Select
										native
										value={this.state.id_cat_saeko_tag}
										onChange={this.handleChange}
										name="id_cat_saeko_tag"
										input={<Input/>}
										disabled={this.props.tipo === 'view'}
									>
										<option value=""/>
										{this.props.catSaekoTag.map(option => (
											<option key={option.id_cat_saeko_tag}
											        value={option.id_cat_saeko_tag}>
												{option.saeko_tag}
											</option>
										))}
									
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="rfc"
									onChange={this.handleChange}
									label="RFC"
									autoComplete={'off'}
									value={this.state.rfc}
								/>
							</Grid>
						</Grid>
					</Grid>*/}
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<PersonOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									type={'text'}
									fullWidth
									name="correo_electronico"
									onChange={this.handleChange}
									label="Correo electrónico"
									autoComplete={'off'}
									value={this.state.correo_electronico}
								/>
							</Grid>
						</Grid>
					</Grid>*/}
					
					{/*<Grid item xs={6} sm={6} md={6} lg={2} xl={2} align={'left'}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrongOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<FormControl fullWidth>
									<InputLabel htmlFor="age-native-activo">Activo</InputLabel>
									<Select
										native
										value={this.state.activo}
										onChange={this.handleChange}
										inputProps={{
											name: 'activo',
											id: 'age-native-activo',
										}}
									>
										<option value={'all'}>Todos</option>
										<option value={1}>Activo</option>
										<option value={0}>Inactivo</option>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>*/}
					
					<Grid item xs={12} sm={12} md={12} lg={2} xl={2} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosSaekoAlumnos.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	catSaekoLead: PropTypes.array,
	catSaekoTag: PropTypes.array
};

export default FiltrosSaekoAlumnos;
